@import url('./styles/animations.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input {
  margin: 0 !important;
  -webkit-appearance: none !important;
  border-radius: 0 !important;
}
input {
  padding: 0 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  font-family: Golos, serif;
  cursor : default;
  display: block;
}

body::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}
@media screen and (max-width: 1024px) {
  body::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
body::-webkit-scrollbar-thumb {
  background: rgba(51, 51, 51, 0.4);
  border-radius: 8px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}


@font-face {
  font-family: 'Golos';
  src: url(font/Golos-Text_Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Golos';
  src: url(font/Golos-Text_Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Golos';
  src: url(font/Golos-Text_Bold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SymbolaRegular";
  src: url('font/Symbola.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.visible {
  opacity: 1;
  visibility: visible;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}
.menu-opened {
  border-bottom: 1px solid #CCCCCC;
  display: flex;
  align-items: center;
  padding: 32px 0;
}
@media screen and (max-width: 1024px) {
  .menu-opened {
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 6;
    background: #fff;
    padding: 0 !important;
    justify-content: flex-start;
  }
  .menu-opened svg {
    position: absolute;
    top: 28px;
    left: 16px;
  }
  .menu-closed {
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .menu-opened svg {
    position: absolute;
    top: 23px;
  }
}
.menu-opened .menu-switch {
  position: absolute;
  top: 32px;
  right: 16px;
}
@media screen and (max-width: 768px) {
  .menu-opened .menu-switch {
    position: absolute;
    top: 27px;
    right: 16px;
  }
}

.menu-switch {
    display: none;
}

@media screen and (max-width: 1024px) {
  .menu-switch {
    display: flex;
  }
}

.menu-opened-row {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
}
.menu-closed-row {
  flex-direction: row;

}

@media screen and (max-width: 1024px) {
  .menu-contact-button {
    display: none;
  }
}

.underline {
  border-bottom: 1px solid #CCCCCC !important;
}

.underline-error {
  border-bottom: 1px solid #ff4828 !important;
}