@keyframes opacity1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes height1 {
    0%  {
        transform: translateY(50px);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes height2 {
    0%  {
        transform: translateY(30px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes width1 {
    0%  {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
@keyframes scale1 {
    0%  {
        transform: scale(1);
        opacity: 0;
    }

    100% {
        transform: scale(1.1);
        opacity: 1;
    }
}
.animation1 {
    animation: height1 550ms linear forwards;
}
.animation2 {
    animation: opacity1 800ms linear forwards;
}
.animation3 {
    animation: height2 800ms linear forwards;
}
.animation4 {
    animation: width1 800ms linear forwards;
}
.animation5 {
    animation: scale1 800ms linear forwards;
}